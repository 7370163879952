import { useState } from 'react';
import { ConfirmModal } from './confirm-modal.component';

export const useConfirm = (
  title: string,
  message?: string
): { ConfirmationDialog: () => JSX.Element; confirm: () => Promise<boolean> } => {
  const [promise, setPromise] = useState<{ resolve: (_: boolean) => void } | null>(null);

  const confirm = (): Promise<boolean> =>
    new Promise((resolve) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const ConfirmationDialog = () => (
    <ConfirmModal open={!!promise} title={title} subtitle={message} onConfirm={handleConfirm} onClose={handleCancel} />
  );

  return { ConfirmationDialog, confirm };
};
