import { FieldArray, Form, Formik, FormikErrors, FormikProps } from 'formik';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ISendClientInvitesProps } from '../invite.types';
import { FormInfo } from '../../../_core/form/form-info.component';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { FormInput } from '../../../_core/form/input/form-input.component';
import { Button } from '../../../_core/button/button.component';
import { IEntity } from '../../../../lib/types';
import { useMemo } from 'react';

interface ISendClientInvitesFormProps extends IRIFormProps<ISendClientInvitesProps> {
  entities: IEntity[];
  entity?: IEntity; // Default entity
}

export const SendClientInvitesForm = ({ entity, entities, onBack, onSkip, onSubmit }: ISendClientInvitesFormProps) => {
  const validateForm = (values: ISendClientInvitesProps) => {
    const errors: FormikErrors<ISendClientInvitesProps> = {};

    if (!values.invites.length) errors.invites = 'At least one invite is required';
    const inviteErrors: string[] = [];
    for (let i = 0; i < values.invites.length; i++) {
      const invite = values.invites[i];
      if (!invite.email?.trim()) {
        inviteErrors.push('Email is required');
      } else {
        inviteErrors.push('');
      }
    }

    if (inviteErrors.some((e) => e)) errors.invites = inviteErrors;

    return errors;
  };

  const defaultEntity = useMemo(() => (entity ? [{ label: entity.name, value: entity._id }] : []), [entity]);
  const entityOptions = useMemo(() => entities.map((d) => ({ label: d.name, value: d._id })), [entities]);

  return (
    <Formik
      initialValues={{ invites: [{ email: '', name: '', entity: defaultEntity }] } as ISendClientInvitesProps}
      onSubmit={(values) =>
        onSubmit({ invites: values.invites.map((i) => ({ ...i, email: i.email?.trim(), name: i.name?.trim() })) })
      }
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ISendClientInvitesProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInfo
            title={'Add Contacts:'}
            messages={[
              'Add some contacts for your organization below.',
              'You can also access the contacts list in your settings.'
            ]}
          />
          <FieldArray name="invites">
            {({ push, remove }) => (
              <div className="w-full flex flex-col gap-4">
                {props.values.invites.map((invite, i) => {
                  const email = `invites[${i}].email`;
                  const entity = `invites[${i}].entity`;
                  const name = `invites[${i}].name`;

                  const error = props.errors.invites?.[i];
                  const touched = Object.values(props.touched.invites?.[i] ?? {}).some((v) => v);
                  return (
                    <div key={'invite-' + i}>
                      <div className="flex flex-row gap-x-2">
                        <FormInput
                          containerClass="w-1/3"
                          id={email}
                          name={email}
                          placeholder="Email"
                          value={invite.email}
                          type="email"
                        />
                        <FormInput
                          containerClass="w-1/3"
                          id={name}
                          name={name}
                          value={invite.name}
                          type="text"
                          placeholder="Name"
                        />
                        <FormInput
                          containerClass="w-1/3"
                          id={entity}
                          name={entity}
                          value={invite.entity}
                          type="multiselect"
                          placeholder="Entity"
                          multiSelectProps={{ options: entityOptions, noMargin: true }}
                        />
                        <Button
                          type="button"
                          variant="primary"
                          onClick={() => remove(i)}
                          icon={<XMarkIcon height={24} width={24} />}
                          size="fit"
                        />
                      </div>
                      {!!error && touched && <span className="my-1.5 text-red-500 text-sm">{String(error)}</span>}
                    </div>
                  );
                })}
                {!!props.errors.invites && !Array.isArray(props.errors.invites) && (
                  <span className="my-1.5 text-red-500 text-sm">{props.errors.invites}</span>
                )}
                <div className="flex">
                  <FormButton
                    text="+"
                    type="button"
                    variant="secondary"
                    onPress={() => push({ email: '', name: '', entity: defaultEntity })}
                  />
                </div>
              </div>
            )}
          </FieldArray>
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
            {!!onSkip && <FormButton onPress={onSkip} text="Skip" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text="Submit" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
