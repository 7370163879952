import { Field, FieldConfig } from 'formik';
import { useFormFieldMeta } from '../form-field-meta';
import { FormTagsInput, IFormTagsInputProps } from './form-tags-input.component';
import { FormMultiSelectInput } from './form-multiselect-input.component';
import { FormSelectInput, IFormSelectInputProps } from './form-select-input.component';
import { IMultiSelectInputProps } from '../../input/multiselect-input.component';
import { InputType } from '../../input/input.type';
import { getInputClasses } from '../../input/input-utils';

export interface IFormInputProps extends FieldConfig {
  containerClass?: string;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  type?: InputType;

  // Input specific props
  multiSelectProps?: IMultiSelectInputProps;
  selectProps?: IFormSelectInputProps;
  tagsProps?: IFormTagsInputProps;
}

export const FormInput = ({
  containerClass,
  multiSelectProps,
  selectProps,
  tagsProps,
  type = 'text',
  ...props
}: IFormInputProps) => {
  const { hasError, error } = useFormFieldMeta(props.name);

  const isCheckbox = type === 'checkbox';
  const errorMessage = hasError ? <span className="my-1.5 text-red-500 text-sm">{error}</span> : undefined;

  const { finalContainerClass, inputClasses } = getInputClasses({ containerClass, hasError, type });

  if (type === 'tags')
    return (
      <FormTagsInput
        {...props}
        {...tagsProps}
        inputClasses={inputClasses}
        error={errorMessage}
        containerClass={finalContainerClass}
      />
    );
  else if (type === 'multiselect')
    return (
      <FormMultiSelectInput
        {...props}
        {...multiSelectProps}
        inputClasses={inputClasses}
        error={errorMessage}
        containerClass={finalContainerClass}
      />
    );
  else if (type === 'select')
    return (
      <FormSelectInput
        {...props}
        {...selectProps}
        inputClasses={inputClasses}
        error={errorMessage}
        containerClass={finalContainerClass}
      />
    );

  return (
    <div className={finalContainerClass}>
      <Field
        fid={props.id}
        type={type}
        checked={isCheckbox ? !!props.value : undefined}
        {...props}
        className={inputClasses}
      />
      {errorMessage}
    </div>
  );
};
