import { IClient, INVITE_STATUS, InviteVariant } from '../lib/types';

export const activeStatus = (active: boolean) => (active ? 'Active' : 'Inactive');

export const clientActiveStatus = ({ deletedAt, entityDeletedAt, entityMemberId, user }: IClient) => {
  if (entityMemberId) {
    if (entityDeletedAt) return 'Inactive';
    else if (deletedAt) return 'Contact - Inactive';
    else if (!user.authId) return 'Active - Unregistered';
    return 'Active';
  }

  if (deletedAt) return 'Inactive';
  else if (!user.authId) return 'Active - Unregistered';
  return 'Active';
};

export const inviteStatus = ({ isExpired, sentAt, status }: InviteVariant) => {
  if (isExpired) return 'Expired';
  if (!sentAt) return 'Invited - Not Delivered';
  if (status === INVITE_STATUS.PENDING) return 'Invited - Pending';
};
