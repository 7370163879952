import { Loader } from '../../_core/loader.component';
import { ICompany, IDepartment, IInvite, ILocation, IMember, IRequestType, IUser } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { ITabProps, Tabs } from '../../_core/tabs.component';
import { DetailBox } from '../../_core/details/details-box.component';
import { DetailItem } from '../../_core/details/details-item.component';
import { activeStatus } from '../../../utils/wording.helpers';
import { IUpdateCompanyProps } from '../../domains/companies/company.types';
import { StaffTableContainer } from './staff-table/staff-table.container';
import { DepartmentTableContainer } from './department-table/department-table.container';
import { LocationTableContainer } from './location-table/location-table.container';
import { RequestTypeTable } from './request-type-table/request-type-table.component';
import { useCurrMember } from '../../domains/member/member.service';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { useEffect, useState } from 'react';
import { RegisterCompanyStepper } from '../../domains/companies/register/register-company-stepper.component';
import { ConfirmButton } from '../../_core/confirm/confirm-button.component';
import { IPersonalCompanyProps } from '../../_core/core.types';

interface IFirmProfilePageProps extends IPersonalCompanyProps {
  company?: ICompany;
  completeOnboarding?: boolean;
  departments: IDepartment[];
  invites?: IInvite[];
  locations: ILocation[];
  members?: IMember[];
  inactiveCompanyRequestTypes: IRequestType[];
  selectedRequestTypes: IRequestType[];
  updateCompany: (_: IUpdateCompanyProps) => void;
  updating: boolean;
  user: IUser;

  removeSelf: () => void;
  removingSelf: boolean;
}

export const FirmProfilePage: React.FC<IFirmProfilePageProps> = ({
  company,
  completeOnboarding,
  departments,
  invites,
  isPersonalCompany,
  locations,
  members,
  inactiveCompanyRequestTypes,
  removeSelf,
  removingSelf,
  selectedRequestTypes,
  updateCompany,
  updating,
  user
}) => {
  const { isAdmin } = useCurrMember();

  const requestTypesTab: ITabProps = {
    title: 'Request Types',
    content: (
      <RequestTypeTable isAdmin={isAdmin} requestTypes={[...selectedRequestTypes, ...inactiveCompanyRequestTypes]} />
    )
  };

  const [showOnboardingWizard, setShowOnboardingWizard] = useState(false);

  // If curr user is an admin and company onboarding wizard should be automatically shown, then show it
  useEffect(() => {
    if (completeOnboarding && company && !company?.completedOnboarding && isAdmin) setShowOnboardingWizard(true);
  }, [company, completeOnboarding, isAdmin]);

  const requestTypes = [...selectedRequestTypes, ...inactiveCompanyRequestTypes];

  if (!company)
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );

  return (
    <>
      <h2 className="font-bold text-2xl tracking-tight w-full">Organization Information</h2>
      <DetailBox>
        <DetailItem label="Organization" value={company.name} />
        <DetailItem label="Status" value={activeStatus(!company.deletedAt)} />
      </DetailBox>
      <DetailBox endAligned>
        {!isPersonalCompany && (
          <ConfirmButton
            size="xl"
            onConfirm={removeSelf}
            loading={removingSelf}
            buttonTitle="Leave Organization"
            title="Are you sure you want to leave the organization?"
          />
        )}
        {isAdmin && (
          <>
            <Button
              size="xl"
              text={`${company.deletedAt ? 'Una' : 'A'}rchive Organization`}
              onClick={() => updateCompany({ active: !!company.deletedAt })}
              loading={updating}
            />
            {!company.completedOnboarding && (
              <Button
                size="xl"
                text="Complete Onboarding"
                onClick={() => setShowOnboardingWizard(true)}
                loading={updating}
              />
            )}
          </>
        )}
      </DetailBox>
      <div className="mt-4 flex flex-1 w-full">
        <DetailBox>
          {isPersonalCompany ? (
            <Tabs tabs={[requestTypesTab]} />
          ) : (
            <Tabs
              tabs={[
                {
                  title: 'Team Members',
                  content: isAdmin ? (
                    invites && members ? (
                      <StaffTableContainer
                        isAdmin={isAdmin}
                        invites={invites}
                        members={members}
                        currUserId={user._id}
                      />
                    ) : (
                      <Loader />
                    )
                  ) : (
                    <DetailBox>You do not have permissions to see the team member table</DetailBox>
                  )
                },
                {
                  title: 'Departments',
                  content: <DepartmentTableContainer isAdmin={isAdmin} departments={departments} />
                },
                { title: 'Locations', content: <LocationTableContainer isAdmin={isAdmin} locations={locations} /> },
                requestTypesTab
              ]}
            />
          )}
        </DetailBox>
      </div>
      <RIDialog open={showOnboardingWizard} setOpen={setShowOnboardingWizard}>
        <RegisterCompanyStepper
          initialData={{ company, departments, locations, requestTypes, invites }}
          close={() => setShowOnboardingWizard(false)}
        />
      </RIDialog>
    </>
  );
};
