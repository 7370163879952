import { Button } from '../../../_core/button/button.component';
import { ACTIVE_STATUS, IInvite, IMember, INVITE_STATUS, ROLE_LABELS } from '../../../../lib/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ITableRowValues } from '../../../_core/table/table.component';
import { activeStatus, inviteStatus } from '../../../../utils/wording.helpers';
import { StaffTable } from './staff-table.component';
import { SelectedStaffModels } from './staff-table.types';
import { StaffTableMultiselectActionButton } from './staff-table-multiselect-action-button/staff-table-multiselect-action-button';
import { UserIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '../../../_core/tooltip.component';
import { useCompanyLocations } from '../../../domains/locations/location.service';
import { useCompanyDepartments } from '../../../domains/departments/department.service';
import { IStaffActionButtonProps, StaffActionButton } from './staff-action-button';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { EditRolesContainer } from '../../../domains/staff/edit-roles/edit-roles-form.container';
import { EditDepartmentsContainer } from '../../../domains/departments/edit/edit-departments-form.container';
import { EditLocationsContainer } from '../../../domains/locations/edit/edit-locations-form.container';
import { IAdminProps } from '../../../_core/core.types';
import { useTableFilterConfig } from '../../../_core/table/table-utils';
import { TableFilterKeys } from '../../../_core/table/table.types';

export interface IStaffTableContainerProps extends IAdminProps {
  currUserId: string;
  invites: IInvite[];
  members: IMember[];
}

interface IStaffTableRowValues extends ITableRowValues {
  invite?: IInvite;
  member?: IMember;
}

export const StaffTableContainer: React.FC<IStaffTableContainerProps> = ({ currUserId, invites, isAdmin, members }) => {
  const [inviteStaff, setInviteStaff] = useState(false);
  const [selected, setSelected] = useState<boolean[] | null>(null);
  const [showEditDepartmentsModal, setShowEditDepartmentsModal] = useState<IInvite | IMember | null>(null);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState<IInvite | IMember | null>(null);
  const [showEditRolesModal, setShowEditRolesModal] = useState<IInvite | IMember | null>(null);

  const [filterConfig, filterFunc] = useTableFilterConfig({
    filters: [
      {
        title: TableFilterKeys.STATUS,
        placeholder: 'Filter by status',
        options: [ACTIVE_STATUS.ACTIVE, INVITE_STATUS.PENDING, 'EXPIRED', ACTIVE_STATUS.INACTIVE],
        value: [ACTIVE_STATUS.ACTIVE],
        matcher: (curr: IMember | IInvite, filterValue: string[]) => {
          if (!filterValue.length) return true;
          if (curr.deletedAt || ('user' in curr && curr.user.deletedAt))
            return filterValue.includes(ACTIVE_STATUS.INACTIVE);
          else if ('user' in curr) return filterValue.includes(ACTIVE_STATUS.ACTIVE);
          else if (curr.isExpired) return filterValue.includes('EXPIRED');
          return filterValue.includes(INVITE_STATUS.PENDING);
        }
      }
    ]
  });

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const onSelect = (index: number, isSelected?: boolean) =>
    setSelected((prev) => {
      if (!prev) return null;
      const newSelected = [...prev];
      newSelected[index] = isSelected !== undefined ? isSelected : !newSelected[index];
      return newSelected;
    });

  const staff = useMemo(() => {
    if (!members || !invites) return null;

    const actionButtonProps: Omit<IStaffActionButtonProps, 'invite' | 'member'> = {
      currUserId,
      departments,
      locations,
      setShowEditDepartmentsModal,
      setShowEditLocationsModal,
      setShowEditRolesModal
    };

    const newStaff: IStaffTableRowValues[] = [];
    members?.filter(filterFunc)?.forEach((member) => {
      const newStaffValues: IStaffTableRowValues = {
        member,
        values: [
          {
            children:
              currUserId === member.user._id ? (
                <Tooltip text="This is you!">
                  <UserIcon className="h-6 mr-2" />
                  {member.user.name}
                </Tooltip>
              ) : (
                member.user.name
              )
          },
          { children: member.user.email },
          {
            children: member.roles
              ?.map((r) => ROLE_LABELS[r])
              .sort()
              .join(', ')
          },
          { children: activeStatus(!member?.deletedAt) },
          {
            children: member.department
              .map((d) => d.name)
              .sort()
              .join(', ')
          },
          {
            children: member.location
              .map((d) => d.name)
              .sort()
              .join(', ')
          }
        ]
      };

      if (isAdmin)
        newStaffValues.values.push({ children: <StaffActionButton {...actionButtonProps} member={member} /> });

      newStaff.push(newStaffValues);
    });

    invites?.filter(filterFunc)?.forEach((invite) => {
      const newStaffValues: IStaffTableRowValues = {
        invite,
        values: [
          { children: null },
          { children: invite.email },
          {
            children: invite.roles
              ?.map((r) => ROLE_LABELS[r])
              .sort()
              .join(', ')
          },
          { children: inviteStatus(invite) },
          {
            children: invite.department
              .map((d) => d.name)
              .sort()
              .join(', ')
          },
          {
            children: invite.location
              .map((d) => d.name)
              .sort()
              .join(', ')
          }
        ]
      };

      if (isAdmin)
        newStaffValues.values.push({ children: <StaffActionButton {...actionButtonProps} invite={invite} /> });

      newStaff.push(newStaffValues);
    });

    return newStaff;
  }, [currUserId, departments, filterFunc, invites, isAdmin, locations, members]);

  // TODO: Want to test this with pagination
  useEffect(() => setSelected((s) => staff?.map((_, i) => !!s && i < s.length && s[i]) ?? null), [staff]);

  const getSelectedModels = useCallback(() => {
    if (selected && staff) {
      const selectedInvites: IInvite[] = [];
      const selectedMembers: IMember[] = [];
      selected.forEach((s, i) => {
        if (s && i < staff.length) {
          const curr = staff[i];
          if (curr.member) selectedMembers.push(curr.member);
          else if (curr.invite) selectedInvites.push(curr.invite);
        }
      });

      return { selectedInvites, selectedMembers } as SelectedStaffModels;
    }
  }, [selected, staff]);

  if (!selected || !staff) return null;

  return (
    <>
      <StaffTable
        inviteStaff={inviteStaff}
        isAdmin={isAdmin}
        selected={selected}
        setInviteStaff={setInviteStaff}
        staff={staff}
        onSelect={onSelect}
        onSelectAll={(v) =>
          setSelected((s) => {
            if (s) return s.map(() => v);
            return null;
          })
        }
        filterConfig={filterConfig}
      >
        {isAdmin && (
          <>
            <Button onClick={() => setInviteStaff(true)} text="Invite Team Members" size="xl" />
            <StaffTableMultiselectActionButton
              currUserId={currUserId}
              getSelectedModels={getSelectedModels}
              selected={selected}
              setSelected={setSelected}
            />
          </>
        )}
      </StaffTable>
      {!!departments && (
        <RIDialog open={!!showEditDepartmentsModal} setOpen={(o) => setShowEditDepartmentsModal((s) => (o ? s : null))}>
          <EditDepartmentsContainer
            departments={departments}
            initialDepartments={showEditDepartmentsModal?.department ?? []}
            targetType={showEditDepartmentsModal && 'code' in showEditDepartmentsModal ? 'invite' : 'member'}
            targetId={showEditDepartmentsModal?._id ?? ''}
          />
        </RIDialog>
      )}
      {!!locations && (
        <RIDialog open={!!showEditLocationsModal} setOpen={(o) => setShowEditLocationsModal((s) => (o ? s : null))}>
          <EditLocationsContainer
            locations={locations}
            initialLocations={showEditLocationsModal?.location ?? []}
            targetType={showEditLocationsModal && 'code' in showEditLocationsModal ? 'invite' : 'member'}
            targetId={showEditLocationsModal?._id ?? ''}
          />
        </RIDialog>
      )}
      <RIDialog open={!!showEditRolesModal} setOpen={(o) => setShowEditRolesModal((s) => (o ? s : null))}>
        <EditRolesContainer
          invite={showEditRolesModal && 'code' in showEditRolesModal ? (showEditRolesModal as IInvite) : undefined}
          member={showEditRolesModal && !('code' in showEditRolesModal) ? (showEditRolesModal as IMember) : undefined}
        />
      </RIDialog>
    </>
  );
};
