import { useMutation, useQuery } from '@apollo/client';

import { IEntityMemberParams, IEntityMemberResults } from './entity-members.types';
import { IEntityMember, Identifiable } from '../../../lib/types';
import { useCallback } from 'react';
import { updateEntityMemberCache } from './entity-members.utils';
import { DELETE_ENTITY_MEMBER, GET_ENTITY_MEMBERS, RESTORE_ENTITY_MEMBER } from './entity-members.queries';

export const useEntityMembers = (variables: IEntityMemberParams) => {
  const { data, ...rest } = useQuery<IEntityMemberResults>(GET_ENTITY_MEMBERS, {
    fetchPolicy: 'cache-and-network',
    variables
  });
  return { ...rest, ...data };
};

export const useDeleteEntityMember = () => {
  const [mutation, rest] = useMutation<{ deleteEntityMember: IEntityMember }, Identifiable>(DELETE_ENTITY_MEMBER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        updateEntityMemberCache({
          cache,
          entityMembers: [data.deleteEntityMember],
          variables: { entityId: data.deleteEntityMember.entity._id }
        });

        updateEntityMemberCache({
          cache,
          entityMembers: [data.deleteEntityMember],
          variables: { userId: data.deleteEntityMember.user._id }
        });
      }
    }
  });

  const deleteEntityMember = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteEntityMember, ...rest };
};

export const useRestoreEntityMember = (variables: IEntityMemberParams) => {
  const [mutation, rest] = useMutation<{ restoreEntityMember: IEntityMember }, Identifiable>(RESTORE_ENTITY_MEMBER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateEntityMemberCache({ cache, entityMembers: [data.restoreEntityMember], variables });
    }
  });

  const restoreEntityMember = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreEntityMember, ...rest };
};
