import { OutputData } from '@editorjs/editorjs';
import { IAsset, IRequestTokenParams } from '../../../lib/types';
import { showError } from '../../../lib/utils';
import { useUploadAsset } from '../../domains/assets/asset.service';

interface IUploadInput extends IRequestTokenParams {
  disabled?: boolean;
  onSave: () => Promise<OutputData | undefined>;
  onUploaded: (_: IAsset) => void;
  readonly: boolean;
  uid: string;
}

export const UploadInput = ({ disabled, onSave, onUploaded, uid, readonly, requestToken }: IUploadInput) => {
  const { uploadAsset } = useUploadAsset(requestToken);

  const onDrop = (e: DragEvent) => {
    e.preventDefault();
    onAddFiles(e.dataTransfer?.files);
  };

  const onAddFiles = (files?: FileList | null) => {
    if (files) {
      for (let i = 0; i < files.length; i++) {
        uploadAsset(files[i])
          .then(({ data }) => {
            onSave();
            onUploaded(data.asset);
          })
          .catch((err) => showError('Failed to upload file.', err));
      }
    }
  };

  const inputId = 'upload-input-' + uid;
  return (
    <label
      htmlFor={inputId}
      className={`flex flex-col items-center justify-center w-full border-2 border-gray-200 border-dashed rounded-lg bg-gray-100 ${
        disabled ? '' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'
      }`}
      onDrop={(e) => onDrop(e.nativeEvent)}
      onDrag={(e) => e.preventDefault()}
    >
      <div className="flex items-center justify-center py-1">
        <svg viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" className="w-4 h-4">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
          ></path>
        </svg>
        <span className="text-xs leading-4 text-gray-500 ml-2">
          <span className="font-semibold">Click to upload</span>
          {!readonly && <span> or drag and drop</span>}
        </span>
      </div>
      <input
        accept="application/msword, .xls, .csv, .txt, .pdf, image/*, .heic, .xlsx, .numbers, .pages, .doc, .docx"
        multiple
        type="file"
        className="hidden"
        id={inputId}
        onChange={(e) => onAddFiles(e.target.files)}
        disabled={disabled}
      />
    </label>
  );
};
