import { useMemo, useRef, useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { RegisterCompanyOption } from '../company.types';
import { SelectCompanyRegisterOption } from './select-company-register-option.component';
import { RegisterCompanySteps } from './register-company-steps.component';
import { IFormFinishProps } from '../../../_core/form/form.types';
import { EditRequestTypesNestedContainer } from '../edit-request-types/edit-request-types-form-nested.container';
import { AcceptInviteNestedContainer } from '../../invite/accept/accept-invite-form-nested.container';
import { ICompany, IDepartment, IInvite, ILocation, IRequestType, PLAN_NAME } from '../../../../lib/types';
import { useUpdateCompany } from '../company.service';
import { logError } from '../../../../lib/utils';
import { COMPLETE_ONBOARDING_KEY } from '../../../../lib/const';
import toast from 'react-hot-toast';
import { REGISTER_COMPANY_STEPPER_ID } from '../company.const';
import { ICloseableDialogProps } from '../../../_core/core.types';

interface IRegisterCompanyStepperProps extends IFormFinishProps, ICloseableDialogProps {
  initialData?: {
    company?: ICompany;
    departments?: IDepartment[];
    invites?: IInvite[];
    locations?: ILocation[];
    requestTypes?: IRequestType[];
  };
}

export const RegisterCompanyStepper = ({ initialData, onFinish, close }: IRegisterCompanyStepperProps) => {
  const { updateCompany } = useUpdateCompany();

  const contentRef = useRef<HTMLDivElement>(null);

  const [completed, setCompleted] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<RegisterCompanyOption | null>(null);

  const completeOnboarding = () =>
    updateCompany({ completedOnboarding: true })
      .then(() => toast.dismiss(COMPLETE_ONBOARDING_KEY))
      .catch((err) => {
        const errMsg = 'Unable to complete onboarding';
        logError(errMsg, err);
        setSubmitError('Unable to complete onboarding');
      });

  const isPersonal = initialData?.company?.plan.name === PLAN_NAME.PERSONAL;
  const initialStep = useMemo(() => {
    if (initialData?.company) {
      if (isPersonal || !initialData.locations?.length) return 1;
      if (!initialData.departments?.length) return 2;
      if (!initialData.requestTypes?.length) return 3;
      if (!initialData.invites?.length) return 4;
    }

    return 0;
  }, [initialData, isPersonal]);

  const isRegisterPersonal =
    (initialStep > 0 && isPersonal) || selectedOption === RegisterCompanyOption.REGISTER_PERSONAL;
  const isRegisterFirm = (initialStep > 0 && !isPersonal) || selectedOption === RegisterCompanyOption.REGISTER_FIRM;
  const isJoinFirm = selectedOption === RegisterCompanyOption.JOIN_WITH_CODE;

  // Can go back from join or create firm options, but create personal firm will create the firm immediately so we shouldn't allow back tracking after that
  const onBack = () => setSelectedOption(null);

  return (
    <>
      <FormContainer
        id={REGISTER_COMPANY_STEPPER_ID}
        contentRef={contentRef}
        error={submitError}
        setError={setSubmitError}
        success={success}
        setSuccess={setSuccess}
        title={
          isRegisterPersonal
            ? 'Finish setting up individual request account '
            : isRegisterFirm
            ? 'Create Organization'
            : isJoinFirm
            ? 'Join Organization'
            : 'What type of an account would you like to set up?'
        }
      >
        {isRegisterPersonal ? (
          completed ? null : (
            <EditRequestTypesNestedContainer
              onError={setSubmitError}
              onSkip={() => {
                setCompleted(true);
                setSuccess('Completed setting up individual request account');
                completeOnboarding();
                if (onFinish) onFinish();
              }}
              onSuccess={(msg) => {
                setCompleted(true);
                setSuccess(msg);
                completeOnboarding();
                if (onFinish) onFinish();
              }}
            />
          )
        ) : isRegisterFirm ? (
          <RegisterCompanySteps
            contentRef={contentRef}
            initialStep={initialStep}
            onBack={onBack}
            onError={setSubmitError}
            onSuccess={setSuccess}
            onFinish={onFinish}
            completeOnboarding={completeOnboarding}
            close={close}
          />
        ) : isJoinFirm ? (
          <AcceptInviteNestedContainer
            onBack={onBack}
            onError={setSubmitError}
            onSuccess={(msg) => {
              setSuccess(msg);
              if (msg !== null && onFinish) onFinish();
            }}
          />
        ) : (
          <SelectCompanyRegisterOption
            onError={setSubmitError}
            onSuccess={setSuccess}
            continueWithOption={setSelectedOption}
          />
        )}
      </FormContainer>
    </>
  );
};
