import { useCallback } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { IRequest, IRequestBlock, Identifiable, REQUEST_NOTIFY_OPTION } from '../../../lib/types';
import {
  CREATE_REQUEST,
  DELETE_REQUEST,
  GET_REQUEST,
  GET_REQUEST_WITH_TOKEN,
  LIST_REQUESTS_AS_STAFF,
  RESTORE_REQUEST,
  SEND_REQUEST,
  SEND_REQUEST_REMINDER,
  UPDATE_REQUEST_WITH_TOKEN,
  UPDATE_REQUEST,
  UPDATE_REQUEST_AS_CLIENT,
  CLEAR_REQUEST,
  DUPLICATE_REQUEST
} from './request.queries';
import {
  ICreateRequestCallParams,
  IListRequestAsStaffResults,
  IRequestByIdResults,
  IRequestWithTokenResults,
  ISendRequestParams,
  IUpdateRequestCallParams
} from './request.types';
import toast from 'react-hot-toast';
import { mergeCacheLists, showError } from '../../../lib/utils';
import { readRequestsCache, writeRequestsCache } from './request.utils';

interface IRequestWithTokenParams {
  requestId: string;
  token: string;
}

const onUpdateRequestError = (error: ApolloError, action: string) => {
  if (error.message.includes('One or more contact IDs do not have access to this request')) {
    toast.error('One or more contacts do not have access to this request');
  } else showError(`Failed to ${action} request`, error);
};

export const useClearRequest = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ clearRequest: IRequest }>(CLEAR_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.clearRequest) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.clearRequest } as IRequestByIdResults,
          variables: { _id }
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: mergeCacheLists(listRequestsAsStaff, [data.clearRequest])
          });
      }
    },
    onCompleted: () => toast.success('Request cleared successfully!'),
    onError: (error) => onUpdateRequestError(error, 'clear')
  });

  const clearRequest = useCallback(() => mutation({ variables: { _id } }), [_id, mutation]);

  return { clearRequest, ...rest };
};

export const useDuplicateRequest = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ duplicateRequest: IRequest }>(DUPLICATE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.duplicateRequest) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.duplicateRequest } as IRequestByIdResults,
          variables: { _id: data.duplicateRequest._id }
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: mergeCacheLists(listRequestsAsStaff, [data.duplicateRequest])
          });
      }
    },
    onCompleted: () => toast.success('Request duplicated successfully!'),
    onError: (error) => {
      onUpdateRequestError(error, 'duplicate');
      throw error;
    }
  });

  const duplicateRequest = useCallback((clear: boolean) => mutation({ variables: { _id, clear } }), [_id, mutation]);

  return { duplicateRequest, ...rest };
};

export const useRequestWithToken = (variables: IRequestWithTokenParams) => {
  const { data, ...rest } = useQuery<IRequestWithTokenResults, IRequestWithTokenParams>(GET_REQUEST_WITH_TOKEN, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  return { ...rest, request: data?.requestByWithToken };
};

export const useRequestWithAuth = (variables: Identifiable) => {
  const { data, ...rest } = useQuery<IRequestByIdResults, Identifiable>(GET_REQUEST, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  return { ...rest, request: data?.requestById };
};

export const useRequests = () => {
  const { data, ...rest } = useQuery<IListRequestAsStaffResults>(LIST_REQUESTS_AS_STAFF, {
    fetchPolicy: 'cache-and-network'
  });
  return { ...rest, requests: data?.listRequestsAsStaff };
};

export const useCreateRequest = () => {
  const [mutation, rest] = useMutation<{ createRequest: IRequest }>(CREATE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.createRequest) {
        cache.writeQuery({ query: GET_REQUEST, data: { requestById: data.createRequest } as IRequestByIdResults });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({ cache, listRequestsAsStaff: [...listRequestsAsStaff, data.createRequest] });
      }
    },
    onCompleted: () => toast.success('Request created successfully!'),
    onError: (error) => {
      if (error.message.includes('One or more contact IDs do not have access to this request')) {
        toast.error('One or more contacts do not have access to this request');
      }
    }
  });

  const createRequest = useCallback((variables: ICreateRequestCallParams) => mutation({ variables }), [mutation]);

  return { createRequest, ...rest };
};

export const useSendRequest = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ sendRequest: IRequest }>(SEND_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.sendRequest) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.sendRequest } as IRequestByIdResults,
          variables: { _id }
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({ cache, listRequestsAsStaff: mergeCacheLists(listRequestsAsStaff, [data.sendRequest]) });
      }
    },
    onCompleted: () => toast.success('Request sent successfully!'),
    onError: (error) => {
      const errMsg = error.message.toLowerCase();
      if (errMsg.includes('blocks are required')) toast.error('Request must have content');
      else if (errMsg.includes('the request must be assigned to a contact'))
        toast.error('Request must be assigned to a contact');
      else showError('Failed to send request', error);
    }
  });

  const sendRequest = useCallback(
    (variables: ISendRequestParams) => mutation({ variables: { ...variables, _id } }),
    [_id, mutation]
  );

  return { sendRequest, ...rest };
};

export const useSendRequestReminder = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ sendRequestReminder: IRequest }>(SEND_REQUEST_REMINDER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.sendRequestReminder) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.sendRequestReminder } as IRequestByIdResults,
          variables: { _id }
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: mergeCacheLists(listRequestsAsStaff, [data.sendRequestReminder])
          });
      }
    },
    onCompleted: () => toast.success('Request reminder sent successfully!'),
    onError: (error) => showError('Failed to send request reminder', error)
  });

  const sendRequestReminder = useCallback(
    () => mutation({ variables: { _id, notify: [REQUEST_NOTIFY_OPTION.CLIENTS, REQUEST_NOTIFY_OPTION.ASSIGNEE] } }),
    [_id, mutation]
  );

  return { sendRequestReminder, ...rest };
};

export const useUpdateRequest = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ updateRequest: IRequest }>(UPDATE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateRequest) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.updateRequest } as IRequestByIdResults,
          variables: { _id }
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: mergeCacheLists(listRequestsAsStaff, [data.updateRequest])
          });
      }
    },
    onCompleted: () => toast.success('Request updated successfully!'),
    onError: (error) => onUpdateRequestError(error, 'update')
  });

  const updateRequest = useCallback(
    (variables: IUpdateRequestCallParams) => mutation({ variables: { ...variables, _id } }),
    [_id, mutation]
  );

  return { updateRequest, ...rest };
};

export const useUpdateRequestAsClient = (variables: IRequestWithTokenParams) => {
  const [asClientMutation, asClientRest] = useMutation<{ updateRequestAsClient: IRequest }>(UPDATE_REQUEST_AS_CLIENT, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateRequestAsClient) {
        cache.writeQuery({
          query: GET_REQUEST_WITH_TOKEN,
          data: { requestByWithToken: data.updateRequestAsClient } as IRequestWithTokenResults,
          variables
        });
      }
    },
    onCompleted: () => toast.success('Request updated successfully!'),
    onError: (error) => showError('Failed to update request', error)
  });

  const [withTokenMutation, withTokenRest] = useMutation<{ updateRequestWithToken: IRequest }>(
    UPDATE_REQUEST_WITH_TOKEN,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data?.updateRequestWithToken) {
          cache.writeQuery({
            query: GET_REQUEST_WITH_TOKEN,
            data: { requestByWithToken: data.updateRequestWithToken } as IRequestWithTokenResults,
            variables
          });
        }
      },
      onCompleted: () => toast.success('Request updated successfully!'),
      onError: (error) => showError('Failed to update request', error)
    }
  );

  const updateRequestAsClient = useCallback(
    (blocks: IRequestBlock[]) => {
      const mutationData: { variables: { blocks: IRequestBlock[]; _id: string; token?: string } } = {
        variables: { blocks, _id: variables.requestId }
      };

      if (variables.token) {
        mutationData.variables.token = variables.token;
        return withTokenMutation(mutationData);
      }
      return asClientMutation(mutationData);
    },
    [asClientMutation, variables.requestId, variables.token, withTokenMutation]
  );

  return { updateRequestAsClient, ...(variables.token ? withTokenRest : asClientRest) };
};

export const useDeleteRequest = () => {
  const [mutation, rest] = useMutation<{ deleteRequest: IRequest }, Identifiable>(DELETE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: mergeCacheLists(listRequestsAsStaff, [data.deleteRequest])
          });
      }
    }
  });

  const deleteRequest = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteRequest, ...rest };
};

export const useRestoreRequest = () => {
  const [mutation, rest] = useMutation<{ restoreRequest: IRequest }, Identifiable>(RESTORE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: mergeCacheLists(listRequestsAsStaff, [data.restoreRequest])
          });
      }
    }
  });

  const restoreRequest = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreRequest, ...rest };
};
