import { GetInviteByCodeForm } from './get-invite-by-code-form.component';
import { sleep } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useLazyInvite } from '../invite.service';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';
import { useAuth0 } from '@auth0/auth0-react';
import { INVITE_STATUS, InviteVariant } from '../../../../lib/types';
import { FetchInviteErrors } from '../invite.types';
import { useLazyClientInvite } from '../../client-invite/client-invite.service';
import { IGetInviteByCodeFormParams, InviteType } from './get-invite-by-code-form.types';

export const GetInviteByCodeContainer = () => {
  const { state } = useLocation();
  const { loginWithPopup } = useAuth0();
  const { getClientInvite } = useLazyClientInvite();
  const { getInvite } = useLazyInvite();
  const navigate = useNavigate();

  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleGetInvite = async ({ code, type }: IGetInviteByCodeFormParams) => {
    await resetFormMessages({ setSubmitError, setSuccess });

    const isStaffInvite = type === InviteType.STAFF;
    const { data, error } = isStaffInvite
      ? await getInvite({ variables: { code, status: INVITE_STATUS.PENDING } })
      : await getClientInvite({ variables: { code, status: INVITE_STATUS.PENDING } });

    if (!error && data) {
      const invite: InviteVariant | null = 'invite' in data ? data.invite : data.clientInvite;
      if (!invite) setSubmitError('Unable to find invite.');
      else if (invite.isExpired) setSubmitError('Invite expired.');
      else if (invite.company.deletedAt) setSubmitError('Organization has been deleted.');
      else {
        setSuccess('Successfully found invite.');
        await sleep(250);
        loginWithPopup().finally(() => navigate(`${ROUTE_PATHS.JOIN}/${code}?type=${type}`));
      }
    } else if (error) {
      setSubmitError(error.message === FetchInviteErrors.EXPIRED ? 'Invite expired.' : 'Invite does not exist.');
    } else {
      setSubmitError('Unable to find invite.');
    }
  };

  return (
    <FormContainer
      hideLogo
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Find Invite"
    >
      <GetInviteByCodeForm code={state?.params?.code} onSubmit={handleGetInvite} />
    </FormContainer>
  );
};
