import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { InviteType } from '../get-by-code/get-invite-by-code-form.types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';

export interface IAcceptInviteFormValues {
  code: string;
  type: InviteType;
}

interface IAcceptInviteFormProps extends IRIFormProps<IAcceptInviteFormValues> {
  code?: string;
  type?: InviteType;
}

export const AcceptInviteForm = ({ code = '', type = InviteType.STAFF, onSubmit, onBack }: IAcceptInviteFormProps) => {
  const validateForm = (values: IAcceptInviteFormValues) => {
    const errors: FormikErrors<IAcceptInviteFormValues> = {};

    if (!values.code.trim()) errors.code = 'Required';
    if (!values.type) errors.type = 'Required';

    return errors;
  };

  return (
    <Formik
      initialValues={{ code, type }}
      onSubmit={(values) => onSubmit({ code: values.code.trim(), type: values.type })}
      validate={validateForm}
    >
      {(props: FormikProps<IAcceptInviteFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInputWithLabel disabled={!!code} id="code" name="code" label="Invite Code" value={props.values.code} />
          <FormInputWithLabel
            id="type"
            name="type"
            label="Invite Type"
            value={props.values.type}
            type="select"
            selectProps={{ options: [{ value: InviteType.STAFF, label: 'Team Member' }, { value: InviteType.CLIENT }] }}
          />
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text="Accept" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
