import { FormMessage } from './form-message.component';
import { FormTitle } from './form-title.component';
import logo from '../../../../assets/imgs/logo.png';
import { RefObject } from 'react';
import { Transition } from '@headlessui/react';

interface IFormContainerProps extends React.PropsWithChildren {
  contentRef?: RefObject<HTMLDivElement>;
  error?: string | null;
  hideLogo?: boolean;
  id?: string;
  setError?: (error: string | null) => void;
  success?: string | null;
  setSuccess?: (success: string | null) => void;
  title: string;
}

export const FormContainer = ({
  children,
  contentRef,
  error,
  hideLogo,
  id,
  setError,
  success,
  setSuccess,
  title
}: IFormContainerProps) => {
  return (
    <div className="flex min-h-full flex-1 flex-col items-center px-6 py-12 lg:px-8" id={id} ref={contentRef}>
      {!hideLogo && (
        <div className="border-b border-b-gray-200 border-solid flex flex-row justify-center">
          <div className="flex flex-row items-end mb-4">
            <img className="h-16 w-16" src={logo} alt="Request IQ" />
            <h2 className="ml-2 text-4xl sm:text-5xl">Request IQ</h2>
          </div>
        </div>
      )}
      <div className="sm:mx-auto sm:w-full sm:max-w-4xl">
        <FormTitle title={title} />
        {children}
        <Transition
          appear={true}
          show={!!error}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <FormMessage
            type="error"
            onClose={() => {
              if (setError) setError(null);
            }}
          >
            {error}
          </FormMessage>
        </Transition>
        <Transition
          appear={true}
          show={!!success}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <FormMessage
            type="success"
            onClose={() => {
              if (setSuccess) setSuccess(null);
            }}
          >
            {success}
          </FormMessage>
        </Transition>
      </div>
    </div>
  );
};
