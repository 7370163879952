import Header from '@editorjs/header';
import { SimpleImage } from '../../_editor/simple-image';
import { Question } from '../../_editor/question';
import { Radio } from '../../_editor/radio';
import { Checkbox } from '../../_editor/checkbox';
import { YesNo } from '../../_editor/yes-no';
import { Upload } from '../../_editor/upload';
import { ISelectTemplateConfig, SelectTemplate } from '../../_editor/select-template';
import { QuestionGroup, Section } from '../../_editor/section';
import { Branch } from '../../_editor/branch';
import { IEditorToolsConfig, SectionType } from '../../_editor/section/section.types';
import { ICreateTemplateBlockConfig } from '../../_editor/_core/create-template-block';
import { FormEditorType } from './form-editor.types';
import { TEMPLATE_TYPE } from '../../../lib/types';
import { Paragraph } from '../../_editor/paragraph';

export const getEditorTools = (
  config: ICreateTemplateBlockConfig,
  type?: FormEditorType,
  requestToken?: string
): IEditorToolsConfig => {
  const baseTools: IEditorToolsConfig = {
    paragraph: Paragraph
  };

  const mainTools: IEditorToolsConfig = {
    ...baseTools,
    // @ts-expect-error Class typings not aligned
    section: { class: Section, config: { ...config, sectionType: SectionType.SECTION, requestToken } }
  };

  const sectionTools: IEditorToolsConfig = {
    ...baseTools,
    header: {
      class: Header,
      shortcut: 'CMD+SHIFT+H'
    },
    image: {
      // @ts-expect-error - This is a custom tool
      class: SimpleImage,
      inlineToolbar: ['link'],
      config: {
        placeholder: 'Paste image URL'
      }
    },
    // TODO: Fix type errors with custom class API and config params
    // @ts-expect-error Class typings not aligned
    question: { class: Question, config },
    // @ts-expect-error Class typings not aligned
    radio: { class: Radio, config },
    // @ts-expect-error Class typings not aligned
    checkbox: { class: Checkbox, config },
    // @ts-expect-error Class typings not aligned
    yesNo: { class: YesNo, config },
    // @ts-expect-error Class typings not aligned
    upload: { class: Upload, config: { ...config, requestToken } },
    questionGroup: {
      // @ts-expect-error Class typings not aligned
      class: QuestionGroup,
      config: { ...config, sectionType: SectionType.QUESTION_GROUP, requestToken }
    },
    // @ts-expect-error Class typings not aligned
    branch: { class: Branch, config: { ...config, requestToken } }
  };

  if (type === 'base')
    return {
      ...mainTools,
      selectTemplate: {
        // @ts-expect-error Class typings not aligned
        class: SelectTemplate,
        config: { templateType: TEMPLATE_TYPE.SECTION } as ISelectTemplateConfig
      }
    };
  else if (type === 'section')
    return {
      ...sectionTools,
      selectTemplate: {
        // @ts-expect-error Class typings not aligned
        class: SelectTemplate,
        config: { templateType: TEMPLATE_TYPE.BLOCK } as ISelectTemplateConfig
      }
    };
  else return { ...sectionTools, ...mainTools };
};
