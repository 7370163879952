import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { inputClassesLineHeightStr } from '../editor.const';
import { ILabelBlockData, LabelBlockTool } from '../label-block';
import { ICreateTemplateBlockConfig } from '../create-template-block';
import { ROMAN_NUMERAL_KEYS, TITLE_BLOCK_TYPES } from './local-title-block.const';

export class LocalTitleBlockTool<
  T extends ILabelBlockData,
  U extends ICreateTemplateBlockConfig
> extends LabelBlockTool<T, U> {
  private titleId: string;

  constructor(props: BlockToolConstructorOptions<T>) {
    super({ ...props, data: { ...props.data, localTitle: props.data.localTitle ?? '' } });

    this.titleId = 'local-title-' + this.uid;
  }

  protected renderWithLocalTitle(): HTMLDivElement {
    this.renderWithLabel();
    if (!this.wrapper) this.wrapper = document.createElement('div');

    const title = document.createElement('b');
    title.id = this.titleId;
    title.classList.add('self-center', ...inputClassesLineHeightStr.split(' '), 'w-full', 'max-w-4');

    this.wrapper.prepend(title);

    return this.wrapper;
  }

  /**
   * Lifecycle hooks
   */

  private letterize(num: number) {
    const letterCount = Math.ceil(num / 26);
    const char = String.fromCharCode(96 + (num % 26));

    let result = '';
    for (let i = 0; i < letterCount; i++) result += char;
    return result;
  }

  private romanize(num: number) {
    const digits = String(num).split('');
    if (digits) {
      let roman = '';
      let i = 3;
      while (i--) {
        const popped = digits.pop();
        if (popped) roman = (ROMAN_NUMERAL_KEYS[+popped + i * 10] || '') + roman;
      }
      return Array(+digits.join('') + 1).join('m') + roman;
    }
    return 'i';
  }

  private getNestedDepth(element: Element, depth = 0): number {
    const parentEditor = element.parentElement?.closest('.editor-container');
    if (parentEditor) return this.getNestedDepth(parentEditor, depth + 1);
    return depth;
  }

  private updateLocalTitle(updateOtherBlocks = false) {
    if (this.block?.id) {
      const blockTypes = this.api.blocks.getBlockTypes().filter(({ name }) => TITLE_BLOCK_TYPES.includes(name));
      const localTitleIndex = blockTypes.findIndex(({ id }) => this.block !== undefined && id === this.block.id);

      if (localTitleIndex > -1) {
        const title = document.getElementById(this.titleId);
        if (title) {
          const nestedDepth = this.getNestedDepth(title);

          let localTitle: string;
          const remainder = nestedDepth % 3;
          if (remainder === 1) localTitle = String(localTitleIndex + 1);
          else if (remainder === 2) localTitle = this.letterize(localTitleIndex + 1);
          else localTitle = this.romanize(localTitleIndex + 1);

          // Skip dom update unless title changed
          const newTitle = localTitle + ')';
          if (title.textContent !== newTitle) {
            title.textContent = newTitle;
          }
        }

        // Update other blocks in editor
        if (updateOtherBlocks) {
          blockTypes.forEach((block, index) => {
            if (index !== localTitleIndex) this.api.blocks.getById(block.id)?.call('updated');
          });
        }
      }
    }
  }

  /**
   * Called after block content added to the page
   */
  rendered() {
    this.updateLocalTitle();
  }

  /**
   * Called each time block content is updated
   */
  updated() {
    this.updateLocalTitle();
  }

  /**
   * Called after block removed from the page but before instance is deleted
   */
  removed() {
    this.updateLocalTitle(true);
  }

  /**
   * Called after block was moved
   */
  moved() {
    this.updateLocalTitle(true);
  }
}
