import { useCallback, useState } from 'react';
import { ISetSelectedFilterProps, ITableFilterConfig, ITableFilterOption } from './table-with-actions.component';
import _ from 'lodash';
import { ACTIVE_STATUS, Deletable } from '../../../lib/types';
import { TableFilterKeys } from './table.types';

export const activeStatusMatcher = (curr: Deletable, filterValue: string[]) => {
  if (!filterValue.length) return true;
  if (curr.deletedAt) return filterValue.includes(ACTIVE_STATUS.INACTIVE);
  return filterValue.includes(ACTIVE_STATUS.ACTIVE);
};

export const useTableFilterConfig = <T>(props: {
  activeStatusFilter?: boolean;
  filters?: ITableFilterOption<T>[];
}): [ITableFilterConfig<T>, (_: T) => boolean] => {
  const [filters, setFilters] = useState<ITableFilterOption<T>[]>(
    [
      props.activeStatusFilter
        ? {
            title: TableFilterKeys.STATUS,
            placeholder: 'Filter by status',
            options: Object.values(ACTIVE_STATUS),
            value: [ACTIVE_STATUS.ACTIVE],
            matcher: activeStatusMatcher
          }
        : null,
      ...(props.filters ?? [])
    ].filter((v) => !!v) as ITableFilterOption<T>[]
  );

  const handleSetFilters = ({ title, value }: ISetSelectedFilterProps) => {
    setFilters((prev) => {
      const newFilters = [...prev];
      const filterIndex = newFilters.findIndex((f) => f.title === title);
      if (filterIndex >= 0) {
        newFilters[filterIndex].value = value;
      }

      return newFilters;
    });
  };

  const filterFunc = useCallback((curr: T) => _.every(filters, (f) => f.matcher(curr, f.value)), [filters]);

  return [{ options: filters, setSelectedFilters: handleSetFilters }, filterFunc];
};
