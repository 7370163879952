// Interfaces and Types

// Client Enums must match the keys of the Server enums, instead of the text values
export enum ACTIVE_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum INVITE_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED'
}

export enum PLAN_NAME {
  PERSONAL = 'PERSONAL',
  FIRM = 'FIRM'
}

export enum ROLE {
  COMPANY_STAFF = 'COMPANY_STAFF',
  COMPANY_ADMIN = 'COMPANY_ADMIN'
}

export enum TEMPLATE_TYPE {
  BLOCK = 'BLOCK',
  REQUEST = 'REQUEST',
  SECTION = 'SECTION'
}

export enum REQUEST_STATUS {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED'
}

export enum REQUEST_NOTIFY_OPTION {
  ALL = 'ALL',
  STAFF = 'STAFF',
  CLIENTS = 'CLIENTS',
  ASSIGNEE = 'ASSIGNEE'
}

export const ROLE_LABELS = {
  [ROLE.COMPANY_STAFF]: 'Team Member',
  [ROLE.COMPANY_ADMIN]: 'Admin'
};

export const ROLES_FOR_LABEL = Object.entries(ROLE).reduce((acc: Record<string, ROLE>, [key, value]) => {
  acc[key] = value;
  return acc;
}, {});

// Common Model Patterns
export interface CompanyPermissions {
  department: IDepartment[];
  location: ILocation[];
}

export interface Deletable {
  deletedAt?: Date;
}

interface Mutable {
  updatedAt: Date;
}

export interface Identifiable {
  _id: string;
}

export interface InviteVariant {
  email: string;
  code: string;
  expiresAt: Date;
  isExpired: boolean;
  status: INVITE_STATUS;
  sentAt: Date;
  company: ICompany;
}

interface UserVariant {
  user: IUser;
  company: ICompany;
}

// Data Models
export interface IUser extends Identifiable, Deletable {
  authId?: string;
  isAdmin: boolean;
  name: string;
  email: string;
  settings: IUserSettings;
}

export interface IUserSettings {
  activeCompanyId: string | null;
}

export interface IRequestType extends Identifiable, Deletable {
  company?: ICompany;
  type: string;
}

export interface ICompanySettings {
  requestTypes: string[];
}

export interface ICompany extends Identifiable, Deletable {
  completedOnboarding?: boolean;
  name: string;
  plan: IPlan;
  settings: ICompanySettings;
}

export interface IMember extends Identifiable, Deletable, CompanyPermissions, UserVariant {
  userId: string; // TOOD: Is this still used?
  companyId: string; // TOOD: Is this still used?
  roles: ROLE[];
}

export interface IDepartment extends Identifiable, Deletable {
  name: string;
  companyId: string;
  company: ICompany;
}

export interface ILocation extends Identifiable, Deletable {
  name: string;
  company: ICompany;
}

export interface IInvite extends Identifiable, Deletable, CompanyPermissions, InviteVariant {
  companyId: string; // TOOD: Is this still used?
  roles: ROLE[];
}

export interface IPlan {
  name: PLAN_NAME;
}

export interface IRequestBlockTemplate {
  data: JSON;
  type: string;
}

export interface IRequestBlock extends IRequestBlockTemplate {
  id: string;
}

export interface IBaseRequestProps {
  name: string;
  notes?: string;
}

export interface IRequest extends Identifiable, IBaseRequestProps, Mutable {
  assignedTo?: IClient;
  blocks?: IRequestBlock[];
  clientEditorIds?: IClient[];
  clientViewerIds?: IClient[];
  company: ICompany;
  createdBy: IUser;
  deadline?: Date;
  deletedAt?: Date;
  entity?: IEntity;
  requestType?: IRequestType;
  staffEditorIds?: IMember[];
  staffViewerIds?: IMember[];
  status?: REQUEST_STATUS;
  time?: number;
  version?: string;
}

export interface ITemplateBaseDetails extends Identifiable {
  description?: string;
  title: string;
  type: TEMPLATE_TYPE;
  version: string;
}

export interface ITemplate extends ITemplateBaseDetails {
  blocks: IRequestBlockTemplate[];
  company: ICompany;
  description?: string;
  title: string;
  type: TEMPLATE_TYPE;
  version: string;
}

export interface IClient extends Identifiable, Deletable, CompanyPermissions, UserVariant {
  // Client-level name override of underlying user
  name?: string;

  // These are client only fields, used as a tool to quickly associate Clients with their "associated" Entity Members
  entityMemberId?: string;
  entityDeletedAt?: Date;
}

export interface IClientInvite extends Identifiable, Deletable, CompanyPermissions, InviteVariant {
  entity?: IEntity;
}

export interface IEntity extends Identifiable, Deletable, CompanyPermissions {
  company: ICompany;
  mainContact?: IUser;
  name: string;
  status: ACTIVE_STATUS;
}

export interface IEntityMember extends Identifiable, Deletable {
  entity: IEntity;
  user: IUser;
}

export interface IAsset extends Identifiable, Deletable {
  file: string;
  name: string;
  url: string;
}

export interface IClientInviteSendResult {
  client: IClient;
  entityMember?: IEntityMember;
  sentAt?: Date;
}

export interface IRequestTokenParams {
  requestToken?: string;
}
