import { DetailBox } from '../details/details-box.component';
import { getInputClasses } from '../input/input-utils';
import { MultiSelectInput } from '../input/multiselect-input.component';
import { Loader } from '../loader.component';
import { ITableProps, Table } from './table.component';

export interface ITableFilterOption<T> {
  placeholder?: string;
  hidden?: boolean;
  title: string;
  options: string[];
  value: string[];
  matcher: (_: T, __: string[]) => boolean;
}

export interface ISetSelectedFilterProps {
  title: string;
  value: string[];
}

export interface ITableFilterConfig<T> {
  options: ITableFilterOption<T>[];
  setSelectedFilters: (_: ISetSelectedFilterProps) => void;
}

interface ITableWithActionsProps<T> extends ITableProps, React.PropsWithChildren {
  filterConfig?: ITableFilterConfig<T>;
  loading?: boolean;
}

export const TableWithActions = <T,>({ children, filterConfig, loading, ...tableProps }: ITableWithActionsProps<T>) => {
  const filterContainerClass = 'w-48';
  const { finalContainerClass, inputClasses } = getInputClasses({
    containerClass: filterContainerClass,
    type: 'multiselect'
  });

  return (
    <DetailBox stretch>
      <div className="w-full flex justify-end pt-4 gap-4">
        {!!filterConfig && (
          <div className="w-full flex">
            {filterConfig.options
              ?.filter(({ hidden }) => !hidden)
              .map((f) => (
                <MultiSelectInput
                  placeholder={f.placeholder}
                  inputClasses={inputClasses}
                  key={'table-filter-' + f.title}
                  containerClass={finalContainerClass}
                  options={f.options.map((value) => ({ value }))}
                  setValue={(v) =>
                    filterConfig.setSelectedFilters({ title: f.title, value: v.map(({ value }) => value) })
                  }
                  value={f.value.map((value) => ({ value }))}
                />
              ))}
          </div>
        )}
        {children}
      </div>
      {loading ? <Loader /> : <Table {...tableProps} />}
    </DetailBox>
  );
};
